<!-- 忘记密码 -->
<template>
  <div class="dapp-login">
    <div class="welcome">
      <div class="title theme-word-color f36 font-b font-bold">连结钱包</div>
      <div class="sub-title f28 color-999">登录到Pie universe</div>
    </div>
    <div class="content">
      <div class="page-title">
        <img class="wallet" src="../assets/images/icon-wallet.svg" alt="" />
        <div class="word color-333 f28 font-m">通过Dapp登录</div>
      </div>
      <div class="wallet-list">
        <a class="wallet-item" :href="schemes.metamask">
          <div class="avatat">
            <img src="../assets/images/metamask.svg" alt="" />
          </div>
          <div class="name">MetaMask</div>
        </a>
        <!-- <a class="wallet-item" :href="schemes.trust">
          <div class="avatat">
            <img src="../assets/images/trust.svg" alt="" />
          </div>
          <div class="name">Trust Wallet</div>
        </a> -->
        <div class="wallet-item">
          <div class="avatat">
            <img src="../assets/images/icon-link.svg" alt="" />
          </div>
          <div class="name">
            <input type="text" placeholder="输入以太坊地址" />
          </div>
        </div>
      </div>
      <div class="btn border-btn">
        <span class="theme-word-color">取消</span>
      </div>
    </div>
  </div>
</template>

<script>
import schemes from "../common/schemesConst";
export default {
  data() {
    return {
      schemes,
    };
  },
  computed: {
    isIOS() {
      const u = navigator.userAgent;
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    },
  },
  methods: {
    toDappBrowser(url) {
      console.log(url);
    },
  },
};
</script>

<style lang="less">
.dapp-login {
  .welcome {
    padding: 1.36rem 0.48rem 0.96rem 0.48rem;
    background: url("../assets/images/login-bg2.png") no-repeat top center;
    background-size: cover;
    text-align: left;
    .title {
      line-height: 0.54rem;
      margin-bottom: 0.1rem;
    }
    .sub-title {
      line-height: 0.4rem;
    }
  }
  .content {
    padding: 0.48rem 0.48rem;
    transform: translateY(-0.48rem);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background: #fff;
    .page-title {
      display: flex;
      .wallet {
        width: 0.48rem;
        height: 0.48rem;
      }
      .word {
        line-height: 0.5rem;
        margin: 0 0.1rem;
      }
    }
    .wallet-list {
      margin-top: 0.48rem;
      .wallet-item {
        display: flex;
        padding: 0.2rem 0.2rem;
        border-radius: 0.2rem;
        border: 1px solid #eee;
        margin: 0.3rem 0;
        .avatat {
          height: 0.64rem;
          margin-right: 0.3rem;
          img {
            width: 0.64rem;
            height: 0.64rem;
          }
        }
        .name {
          font-size: 0.28rem;
          color: #666;
          line-height: 0.64rem;
          input {
            border: none;
            outline: none;
            height: 0.64rem;
          }
        }
      }
    }
    .border-btn {
      margin-top: 1.6rem;
      width: 100%;
    }
  }
}
</style>
