const hostname = location.hostname;

const httpAddress = location.origin;
// const httpAddress = 'https://eduardo.games/#/?isDappBrowser=isDappBrowser'
// const httpAddress = 'https://nasdaq-ca.com/#/?isDappBrowser=isDappBrowser'

const metamaskUrl = "https://metamask.app.link/dapp/" + hostname;
// const metamaskUrl = 'https://metamask.app.link/dapp/eduardo.games'
// const metamaskUrl = 'https://metamask.app.link/dapp/nasdaq-ca.com'

const tpParams = {
  url: httpAddress,
  chain: "",
  source: hostname,
};
// cur.digital
const schemes = {
  // https://help.tokenpocket.pro/developer-cn/mobile-wallet/deeplink
  tokenPocket: "tpdapp://open?params=" + JSON.stringify(tpParams),
  // https://imtoken.gitbook.io/developers/v/zh/products/deep-linking
  imToken: "imtokenv2://navigate/DappView?url=" + httpAddress,
  // https://developer.trustwallet.com/developer/develop-for-trust/deeplinking
  trust: "trust://open_url?coin_id=60&url=" + httpAddress,
  // https://docs.bitkeep.com/guide/mobile/Deeplink.html#access-dapp
  bitKeep: "bitkeep://bkconnect?action=dapp&url=" + httpAddress,
  // https://docs.metamask.io/wallet/how-to/use-mobile/
  metamask: metamaskUrl,
  // https://blog.safepal.com/developers-guide-how-to-optimize-your-dapp-in-the-safepal-wallet/
  // safepal: 'safepalwallet://'
};

export default schemes;
